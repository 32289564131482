import Vue from "vue";
import { Kiosko, Efectivo } from "apd.sistemapagos.models";
import errorSnackbar from "@/store/modules/snackbarModule";
import { AxiosHttpClient, ApiErrorResponse, ApiSistemaPagos } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";

export default Vue.extend({
  name: "TonelerosDashItemComponent",
  data() {
    return {
      saldos: [] as Efectivo[],
    };
  },
  created() {
    const axiosHttpClientSP: AxiosHttpClient = new AxiosHttpClient(
      getUrl(ApiList.SistemaPagos, this.$store.getters.appMode),
    );
    axiosHttpClientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
    const apiSP: ApiSistemaPagos = new ApiSistemaPagos(axiosHttpClientSP);

    apiSP
      .GetCajerosFisicos()
      .then((resp) => {
        const kioskos: Kiosko[] = resp as Kiosko[];

        if (kioskos != null && kioskos.length > 0) {
          kioskos.forEach((item) => {
            apiSP
              .ConsultarEfectivoDisponible(item.id)
              .then((resp) => {
                const s: Efectivo = resp as Efectivo;
                if (s != null) this.saldos.push(s);
              })
              .catch((resp) => {
                const err: ApiErrorResponse = resp as ApiErrorResponse;
                errorSnackbar.Show(err.apiError.mensajeUsuario);
              });
          });
        }
      })
      .catch((resp) => {
        const err: ApiErrorResponse = resp as ApiErrorResponse;
        errorSnackbar.Show(err.apiError.mensajeUsuario);
      });
  },
});
